import React from "react";
import MobileSection from "./MobileSection";
/* import WebSection from "./WebSection"; */

function App() {
  return (
    <div className="App  ">
      <MobileSection />
      {/*  <WebSection /> */}
    </div>
  );
}

export default App;
